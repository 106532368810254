import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import { Section, Box } from "../../components/Core";
import Slider from "react-slick"
import { breakpoints } from "../../utils"
import { StaticImage } from "gatsby-plugin-image"


const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const Brand = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    arrows: false,
   
    slidesToShow: 5,
   
    autoplay: true,
      speed: 1500,
      autoplaySpeed: 100,
     
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <Section py={4} bg="#fff">
        <Container  className="pb-lg-5 mb pt-lg-5 PB-3 PT-3">
          <Row className="justify-content-center PB-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false">
            <Col md="9" lg="12">
            <Iwrap>


<Itext as="h2">Our <span>Brands</span></Itext>
</Iwrap>
      <SecondText>
   Top of the range
      </SecondText>
             
            </Col>
          </Row>

</Container>
          <Container fluid className="">
          <Row className="justify-content-center mt-0" data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false">
            <Col lg="11"
          
            >
              <SliderStyled {...slickSettings}>
                <Box mx="40px" css={` &:focus {outline: none;}`}>
                  <Box className={`d-flex justify-content-between`}>
                    <Box className="d-flex justify-content-center align-items-start" mr={3}>
                      <div className="img-logo-wrapper">
                        <StaticImage
                          src="../../assets/image/png/juvederm.png"
                          formats={["auto", "webp", "aif"]}
                          alt="juvederm logo"
                          placeholder="blurred"
                          layout="constrained"
                          quality={100}
                          width={224}
                          className="img-fluid"
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>

                <Box mx="40px" css={` &:focus {outline: none;}`}>
                  <Box className={`d-flex justify-content-between`}>
                    <Box className="d-flex justify-content-center align-items-start" mr={3}>
                      <div className="img-logo-wrapper">
                        <StaticImage
                          src="../../assets/image/jpeg/teo.jpg"
                          formats={["auto", "webp", "aif"]}
                          alt="teo logo"
                          placeholder="blurred"
                          layout="constrained"
                          quality={100}
                          width={224}
                          className="img-fluid"
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
                <Box mx="40px" css={` &:focus {outline: none;}`}>
                  <Box className={`d-flex justify-content-between`}>
                    <Box className="d-flex justify-content-center align-items-start" mr={3}>
                      <div className="img-logo-wrapper">
                        <StaticImage
                          src="../../assets/image/png/zo.png"
                          formats={["auto", "webp", "aif"]}
                          alt="zo logo"
                          placeholder="blurred"
                          layout="constrained"
                          quality={100}
                          width={224}
                          className="img-fluid"
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
                <Box mx="40px" css={` &:focus {outline: none;}`}>
                  <Box className={`d-flex justify-content-between`}>
                    <Box className="d-flex justify-content-center align-items-start" mr={3}>
                      <div className="img-logo-wrapper">
                        <StaticImage
                          src="../../assets/image/jpeg/alm.jpg"
                          formats={["auto", "webp", "aif"]}
                          alt="alm logo"
                          placeholder="blurred"
                          layout="constrained"
                          quality={100}
                          width={224}
                          className="img-fluid"
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
                <Box mx="40px" css={` &:focus {outline: none;}`}>
                  <Box className={`d-flex justify-content-between`}>
                    <Box className="d-flex justify-content-center align-items-start" mr={3}>
                      <div className="img-logo-wrapper">
                        <StaticImage
                          src="../../assets/image/jpeg/ac.png"
                          formats={["auto", "webp", "aif"]}
                          alt=""
                          placeholder="blurred"
                          layout="constrained"
                          quality={100}
                          width={224}
                          className="img-fluid"
                        />
                      </div>
                    </Box>
                  </Box>
                </Box>
              </SliderStyled>
            </Col>
          </Row>
          </Container>
      </Section>
    </>
  );
};

export default Brand;
